import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, PostCardSide, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import Masonry from 'react-masonry-component'

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {
    const tag = data.ghostTag
    const posts = data.posts.edges
    const recommended = data.recommended.edges
    const masonryOptions = {
        itemSelector: `.grid-item`,
        columnWidth: `.card_small_with_image`,
        gutter: 30,
    }

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout>
                <div className="tag">
                    <div className="home">
                        <div className="home_background parallax-window" style={{ backgroundImage: `url(${tag.feature_image})` }}></div>
                    </div>
                </div>

                <div className="page_content">
                    <div className="container">
                        <div className="row row-lg-eq-height">
                            <div className="col-lg-9">
                                <div className="main_content">
                                    <div className="blog_section category">
                                        <div className="section_panel d-flex flex-row align-items-center justify-content-start">
                                            <div className="section_title">{tag.name}</div>
                                            <div className="section_tags ml-auto">
                                                {/* <ul>
                                                    <li className="active"><a href="category.html">all</a></li>
                                                    <li><a href="category.html">style hunter</a></li>
                                                    <li><a href="category.html">vogue</a></li>
                                                    <li><a href="category.html">health & fitness</a></li>
                                                    <li><a href="category.html">travel</a></li>
                                                </ul> */}
                                            </div>
                                            <div className="section_panel_more">
                                                {/* <ul>
                                                    <li>more
                                                        <ul>
                                                            <li><a href="category.html">new look 2018</a></li>
                                                            <li><a href="category.html">street fashion</a></li>
                                                            <li><a href="category.html">business</a></li>
                                                            <li><a href="category.html">recipes</a></li>
                                                            <li><a href="category.html">sport</a></li>
                                                            <li><a href="category.html">celebrities</a></li>
                                                        </ul>
                                                    </li>
                                                </ul> */}
                                            </div>
                                        </div>
                                        <div className="section_content">
                                            <div className="grid clearfix">
                                                <Masonry className={`grid clearfix`} options={masonryOptions}>
                                                    {posts.map(({ node }) => (
                                                        <PostCard key={node.id} post={node} />
                                                    ))}
                                                </Masonry>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="sidebar">
                                    <div className="sidebar_background"></div>
                                    <div className="sidebar_section">
                                        <div className="sidebar_title_container">
                                            <div className="sidebar_title">Te Recomendamos</div>
                                        </div>
                                        <div className="sidebar_section_content">
                                            <div className="sidebar_slider_container">
                                                {recommended.map(({ node }) => (
                                                    <PostCardSide key={node.id} post={node} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            feature_image: PropTypes.string,
        }),
        posts: PropTypes.object.isRequired,
        recommended: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        posts: allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        },
        recommended: allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            limit: 6,
            filter: {tags: {elemMatch: {slug: {eq: "recomendados"}}}}
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        },
    }
`
